// import axios from 'axios';

import { DiscoveryApi, createApiRef } from '@backstage/core-plugin-api';
import { ServiceCatalogApiData } from '@internal/backstage-plugin-service-catalog-common';

export interface MyServiceCatalogApi {
  getServiceCatalogList: ({
    vendorRefId,
    systemRefId,
    interfaceName,
    functionName,
    apiId,
  }: {
    vendorRefId: string;
    systemRefId: string;
    interfaceName?: string;
    functionName?: string;
    apiId?: string;
  }) => Promise<ServiceCatalogApiData[]>;
  saveServiceCatalog: (payload: any) => Promise<any[]>;
  updateServiceCatalog: (payload: any) => Promise<any[]>;
  getQAReport: (systemId: any, vendorId: any) => Promise<any>;
  getTeamGroups: () => Promise<any>;
  saveDocumentation: (payload: any) => Promise<any[]>;
  updateDocumentation: (payload: any) => Promise<any[]>;
  getDocumentation: (payload: any) => Promise<any[]>;
}

export const myServiceCatalogApiRef = createApiRef<MyServiceCatalogApi>({
  id: 'plugin.service-catalog.service',
});

export class MyServiceCatalogApiClient implements MyServiceCatalogApi {
  discoveryApi: DiscoveryApi;

  constructor({ discoveryApi }: { discoveryApi: DiscoveryApi }) {
    this.discoveryApi = discoveryApi;
  }

  private async fetch<T = any>(input: string, init?: RequestInit): Promise<T> {
    // As configured previously for the backend proxy
    const proxyUri = `${await this.discoveryApi.getBaseUrl('apicatalog')}`;

    const resp = await fetch(`${proxyUri}${input}`, init);
    if (!resp.ok) throw new Error(resp as any);
    return await resp.json();
  }

  async getServiceCatalogList({
    vendorRefId,
    systemRefId,
    interfaceName,
    functionName,
    apiId,
  }: {
    vendorRefId: string;
    systemRefId: string;
    interfaceName: string;
    functionName: string;
    apiId: string;
  }): Promise<ServiceCatalogApiData[]> {
    const params = new URLSearchParams({
      vendorRefId: `${vendorRefId}`,
      systemRefId: `${systemRefId}`,
    });

    if (interfaceName) {
      params.append('interfaceName', interfaceName);
    }

    if (functionName) {
      params.append('functionName', functionName);
    }

    if (apiId) {
      params.append('apiId', apiId);
    }

    return await this.fetch<ServiceCatalogApiData[]>(`?${params}`);
  }

  async saveServiceCatalog(payload: any): Promise<any> {
    return await this.fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
  }

  async updateServiceCatalog(payload: any): Promise<any> {
    return await this.fetch('/', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
  }

  async getQAReport(systemId: any, vendorId: any): Promise<any[]> {
    return await this.fetch<any[]>(`/qa-report?systemId=${systemId}&vendorId=${vendorId}`);
  }

  async getTeamGroups(): Promise<any> {
    return await this.fetch<any>('/teamgroups');
  }

  async saveDocumentation(payload: any): Promise<any> {
    return await this.fetch('/documentation', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
  }

  async updateDocumentation(payload: any): Promise<any> {
    return await this.fetch('/documentation', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
  }

  async getDocumentation({ apiId }): Promise<any> {
    return await this.fetch<any>(`/documentation?apiId=${apiId}`);
  }
}
